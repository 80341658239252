import ContentWrapper from "../components/ContentWrapper";
import PageHeader from "../components/PageHeader";
import dataContact from "../data/contact.json";
import "./Contact.scss";

export default function Contact() {
  return (
    <ContentWrapper>
      <div className="page-contact">
        <PageHeader title="Sang Lu" tag={dataContact.title} />
        <div className="page-contact__content">{dataContact.message}</div>
        <div className="page-contact__links">
          <a href={"mailto:" + dataContact.email} className="page-contact__links-item">
            <img className="page-contact__links-icon" src="/images/icon-envelope.svg" alt="Email icon" />
            Email<br/><strong>{dataContact.email}</strong>
          </a>
          <a href={dataContact.socials.telegram} className="page-contact__links-item" target="_blank" rel="noreferrer">
            <img className="page-contact__links-icon" src="/images/logo-telegram.svg" alt="Telegram" />
            Telegram<br/><strong>@a_jackie_z</strong>
          </a>
          <a href={dataContact.socials.facebook} className="page-contact__links-item" target="_blank" rel="noreferrer">
            <img className="page-contact__links-icon" src="/images/logo-facebook.svg" alt="Facebook" />
            Facebook<br/><strong>@jackiechunxyz</strong>
          </a>
          <a href={dataContact.socials.linkedin} className="page-contact__links-item" target="_blank" rel="noreferrer">
            <img className="page-contact__links-icon" src="/images/logo-linkedin.svg" alt="Linkedin" />
            LinkedIn<br/><strong>@ajackiez</strong>
          </a>
        </div>
        <div className="page-contact__image">
          <img src="/images/contact.svg" alt="Contact" />
        </div>
      </div>
    </ContentWrapper>
  );
}
