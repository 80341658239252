import "./Sidebar.scss";
import React, {useMemo} from "react";
import {Link, useLocation} from "react-router-dom";
import dataHome from "../data/index.json";

export type TSidebarActiveChecker = (path: string) => boolean;

export type TProps = {
  onItemClick?: (link?: string, label?: string) => void,
}

export default function Sidebar(props: TProps) {
  const location = useLocation();

  const createLink = React.useCallback((link: string, label: string, checker?: TSidebarActiveChecker) => {
    const classes = ["c-sidebar__nav-item"];

    if (checker) {
      if (checker(location.pathname)) {
        classes.push("c-sidebar__nav-item--active");
      }
    } else if (location.pathname === link) {
      classes.push("c-sidebar__nav-item--active");
    }

    return (
      <Link className={classes.join(" ")} to={link} onClick={() => props.onItemClick?.(link, label)}>
        {label}
      </Link>
    );
  }, [location, props]);

  // const linkBlogActiveChecker = React.useCallback((path: string) => {
  //   return path === "/blog.html" || path.startsWith("/blog/");
  // }, []);

  const linkHome = useMemo(() => createLink("/", "Home"), [createLink]);
  const linkAbout = useMemo(() => createLink("/about.html", "About"), [createLink]);
  // const linkBlog = useMemo(() => {
  //   return createLink("/blog.html", "Blog", linkBlogActiveChecker);
  // }, [createLink, linkBlogActiveChecker]);
  const linkContact = useMemo(() => createLink("/contact.html", "Contact"), [createLink]);
  const linkPrivacy = useMemo(() => createLink("/privacy.html", "Privacy policy"), [createLink]);
  const linkTerms = useMemo(() => createLink("/terms.html", "Terms and conditions"), [createLink]);

  return (
    <div className="c-sidebar">
      <div className="c-sidebar__content">
        <div className="c-sidebar__title">{dataHome.title}</div>
        <div className="c-sidebar__nav">
          {linkHome}
          {linkAbout}
          {/*{linkBlog}*/}
          {linkContact}
          {linkPrivacy}
          {linkTerms}
        </div>
        <div className="c-sidebar__copyright">&copy; 2024 Sang Lu</div>
      </div>
    </div>
  )
}
